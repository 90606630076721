<template>
  <div>
    <div class="row">

      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h3 class="card-title">Shop-Konfiguration</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
                <label for="" class="col-md-2 col-form-label">Shop:</label>
                <div class="col-md-10">
                    <select class="form-control form-control-sm" v-model="order.company.id" disabled>
                        <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                    </select>
                </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h3 class="card-title">Rechnungs-Daten</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                <i class="fas fa-minus"></i></button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Vorname:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.first_name">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Nachname:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.last_name">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Firma:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.company">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Abteilung:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.department">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">UstId:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.ustid">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Telefon:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.phone">
                    </div>
                </div>


              </div>


              <div class="col-md-6">
                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Straße:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.street">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Hausnummer:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.house_number">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Postleitzahl:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.zipcode">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Stadt:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.billing.city">
                    </div>
                </div>


              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h3 class="card-title">Alternative-Lieferadresse</h3>
            <div class="card-tools">
              <button type="button" class="btn btn-tool" data-card-widget="collapse" data-toggle="tooltip" title="Collapse">
                <i class="fas fa-minus"></i></button>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Vorname:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.first_name">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Nachname:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.last_name">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Firma:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.company">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Abteilung:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.department">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Telefon:</label>
                    <div class="col-md-8">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.phone">
                    </div>
                </div>


              </div>


              <div class="col-md-6">
                <template v-if="order.shipping.type == 'address'">
                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">Straße:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.street">
                      </div>
                  </div>

                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">Hausnummer:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.house_number">
                      </div>
                  </div>
                </template>

                <template v-if="order.shipping.type == 'packstation'">
                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">DHL Kd-Nr.:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_customer_number">
                      </div>
                  </div>

                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">Paketstation:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_number">
                      </div>
                  </div>
                </template>

                <template v-if="order.shipping.type == 'postfiliale'">
                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">DHL Kd-Nr.:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_customer_number">
                      </div>
                  </div>

                  <div class="form-group row">
                      <label for="" class="col-md-2 col-form-label">Postfiliale:</label>
                      <div class="col-md-10">
                          <input type="text" class="form-control form-control-sm" v-model="order.shipping.post_number">
                      </div>
                  </div>
                </template>
                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Postleitzahl:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.zipcode">
                    </div>
                </div>

                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Stadt:</label>
                    <div class="col-md-10">
                        <input type="text" class="form-control form-control-sm" v-model="order.shipping.city">
                    </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h3 class="card-title">Zahlungs-Daten</h3>
          </div>
          <div class="card-body">
            <div class="form-group row">
                <label for="" class="col-md-2 col-form-label">Shop:</label>
                <div class="col-md-10">
                    <select class="form-control form-control-sm" v-model="order.payment.id">
                        <option v-for="payment in payments" :key="payment.id" :value="payment.id">{{ payment.description }}</option>
                    </select>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <div class="card card-outline card-primary">
          <div class="card-header">
            <h3 class="card-title">Versand-Daten</h3>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group row">
                    <label for="" class="col-md-2 col-form-label">Shop:</label>
                    <div class="col-md-10">
                        <select class="form-control form-control-sm" v-model="order.dispatch.id">
                            <option v-for="dispatch in dispatches" :key="dispatch.id" :value="dispatch.id">{{ dispatch.name }}</option>
                        </select>
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
            <div class="col-md-12">
                <button class="btn btn-primary float-right" @click="saveOrder">Speichern</button>
            </div>
        </div>

  </div>
</template>

<script>
export default {
  name: 'OrderDetailsDetails',
  props: [
      'order'
  ],

  data(){
    return {
      companies: [],
      payments: [],
      dispatches: [],
    }
  },

  methods: {
    saveOrder(){
      this.$Progress.start();
      this.axios
          .put("/orders/" + this.order.id, {
            'billing': this.order.billing,
            'shipping': this.order.shipping,
            'dispatch_id': this.order.dispatch.id,
            'payment_id': this.order.payment.id
          })
          .then(() => {
              // this.billingStates = response.data.data;
              this.$parent.getOrder();
              this.$Progress.finish();
          })
          .catch(() => {
              this.$swal({
                  icon: "error",
                  title: "Oops...",
                  text: "Da ist etwas schief gelaufen",
              });
              this.$Progress.fail();
          });
    },

    getCompanies(){
        this.$Progress.start();
        this.axios
            .get("/companies")
            .then((response) => {
                this.companies = response.data.data;
                this.$Progress.finish();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
    },

    getPayments(){
        this.$Progress.start();
        this.axios
            .get("/payments")
            .then((response) => {
                this.payments = response.data.data;
                this.$Progress.finish();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
    },

    getDispatches(){
        this.$Progress.start();
        this.axios
            .get("/dispatches")
            .then((response) => {
                this.dispatches = response.data.data;
                this.$Progress.finish();
            })
            .catch(() => {
                this.$swal({
                    icon: "error",
                    title: "Oops...",
                    text: "Da ist etwas schief gelaufen",
                });
                this.$Progress.fail();
            });
    },
  },

  created() {
    this.getCompanies();
    this.getPayments();
    this.getDispatches();
  }
}
</script>

<style>

</style>